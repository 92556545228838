// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-ecosystem-js": () => import("./../../../src/pages/about/ecosystem.js" /* webpackChunkName: "component---src-pages-about-ecosystem-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-add-to-glossary-js": () => import("./../../../src/pages/add-to-glossary.js" /* webpackChunkName: "component---src-pages-add-to-glossary-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-demo-genai-js": () => import("./../../../src/pages/demo-genai.js" /* webpackChunkName: "component---src-pages-demo-genai-js" */),
  "component---src-pages-demo-js": () => import("./../../../src/pages/demo.js" /* webpackChunkName: "component---src-pages-demo-js" */),
  "component---src-pages-demo-thank-you-js": () => import("./../../../src/pages/demo/thank-you.js" /* webpackChunkName: "component---src-pages-demo-thank-you-js" */),
  "component---src-pages-enterprise-upskilling-quiz-app-js": () => import("./../../../src/pages/enterprise-upskilling-quiz-app.js" /* webpackChunkName: "component---src-pages-enterprise-upskilling-quiz-app-js" */),
  "component---src-pages-enterprise-upskilling-quiz-js": () => import("./../../../src/pages/enterprise-upskilling-quiz.js" /* webpackChunkName: "component---src-pages-enterprise-upskilling-quiz-js" */),
  "component---src-pages-features-assets-js": () => import("./../../../src/pages/features/assets.js" /* webpackChunkName: "component---src-pages-features-assets-js" */),
  "component---src-pages-features-create-js": () => import("./../../../src/pages/features/create.js" /* webpackChunkName: "component---src-pages-features-create-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-features-learn-js": () => import("./../../../src/pages/features/learn.js" /* webpackChunkName: "component---src-pages-features-learn-js" */),
  "component---src-pages-features-plan-js": () => import("./../../../src/pages/features/plan.js" /* webpackChunkName: "component---src-pages-features-plan-js" */),
  "component---src-pages-features-share-js": () => import("./../../../src/pages/features/share.js" /* webpackChunkName: "component---src-pages-features-share-js" */),
  "component---src-pages-glossary-thank-you-js": () => import("./../../../src/pages/glossary-thank-you.js" /* webpackChunkName: "component---src-pages-glossary-thank-you-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-podcast-js": () => import("./../../../src/pages/podcast.js" /* webpackChunkName: "component---src-pages-podcast-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-solutions-digital-transformation-js": () => import("./../../../src/pages/solutions/digital-transformation.js" /* webpackChunkName: "component---src-pages-solutions-digital-transformation-js" */),
  "component---src-pages-solutions-digital-upskilling-js": () => import("./../../../src/pages/solutions/digital-upskilling.js" /* webpackChunkName: "component---src-pages-solutions-digital-upskilling-js" */),
  "component---src-pages-solutions-generative-ai-js": () => import("./../../../src/pages/solutions/generative-ai.js" /* webpackChunkName: "component---src-pages-solutions-generative-ai-js" */),
  "component---src-pages-subscribe-js": () => import("./../../../src/pages/subscribe.js" /* webpackChunkName: "component---src-pages-subscribe-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-lp-js": () => import("./../../../src/templates/lp.js" /* webpackChunkName: "component---src-templates-lp-js" */),
  "component---src-templates-resource-js": () => import("./../../../src/templates/resource.js" /* webpackChunkName: "component---src-templates-resource-js" */)
}

